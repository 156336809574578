/* You can add global styles to this file, and also import other style files */
body {
  margin: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  height: 100vh;

  app-url-verification {
    display: none;
  }

  div:has(app-image-map) {
    display: none;
  }
}
